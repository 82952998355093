<script>
/* eslint-disable */
import { reactive } from "vue";

export var perMonth = 15000;
export var hourSummary = 14400;
export var hourUnused = 14400;

export var activePage = reactive({
  page: 0, // activePage
  test: 1,
});

const regions = {
  0: {
    load: 10,
  },
  1: {
    load: 11,
  },
  2: {
    load: 12,
  },
  3: {
    load: 13,
  },
  4: {
    load: 14,
  },
  5: {
    load: 15,
  },
  6: {
    load: 16,
  },
  7: {
    load: 17,
  },
  8: {
    load: 18,
  },
  9: {
    load: 19,
  },
};

export default {
  name: "ownerPage",
  props: {
    msg: String,
  },
  data: function () {
    return {
      activePage,
      breakfNum: "",
      breakfRate: "",
      load: regions[0].load,
      isShow: false,
      activeHours: 8,
      minHours: 2,
      yearly: false,
      pages: 8, // quantity of pages
      regions,
      onlineBookings: 25, // online bookings start ratio
    };
  },
  methods: {
    nextPage(bool) {
      if (bool) {
        activePage.page++;
      }
    },
    prevPage() {
      activePage.page--;
    },
    validate() {
      if (this.breakfNum > 0 && this.breakfRate > 0) {
        return true;
      } else {
        return false;
      }
    },
    monthlyYearly(bool) {
      if (!this.yearly) {
        if (bool) {
          return 'month';
        } else return 1;
      } else {
        if (bool) {
          return 'year';
        } else return 12;
      }
    },
    calculateMonthly() {
      return (
        (this.breakfNum * this.breakfRate) * this.monthlyYearly()
      ).toFixed(0);
    },
    openPage(page) {
      this.$router.push(page);
      activePage.page = 0;
    },
  },
};
</script>

<template>
  <Transition name="fade" mode="in-out">
    <div class="container n0" v-if="activePage.page == 0">
      <div class="container-input">
        <input type="text" inputmode="numeric" placeholder="Avg rate" v-model.number="breakfNum" />
        <span>€</span>
      </div>
      <div class="container-input">
        <input type="text" inputmode="numeric" placeholder="Loading" v-model.number="breakfRate" />
        <span>%</span>
      </div>
      <div class="container-input range">
        <h5>Number of bookings</h5>
        <input type="range" min="0" max="100" v-model.number="onlineBookings" />
        <span class="label">online - {{ onlineBookings }}%</span>
        <span class="label">offline - {{ 100 - this.onlineBookings }}%</span>
      </div>
    </div>
  </Transition>
  <div class="container n5" v-if="activePage.page == 1">
    <p>Our research shows that<br>online bookings reach</p>
    <h1>{{ '>50' }}%</h1>
    <p>Lack of bookigns is <b>{{ onlineBookings }}%</b></p>
  </div>
  <Transition name="fade" mode="in-out">
    <div class="container n5" v-if="activePage.page == 2">
      <p>Final cuts</p>
      <h1>{{ '50' }}% → {{ 90 }}%</h1>
      <p>You are not receiving {{ 99 }} bookings per month</p>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n5" v-if="activePage.page == 3">
      <h5>You are not receiving<br><b>{{ 290 }}</b> bookings per <b>{{ monthlyYearly(true) }}</b></h5>
      <div class="hours-container">
        <p>Monthly</p>
        <label class="switch">
          <input type="checkbox" v-model="yearly" />
          <span class="slider round"></span>
        </label>
        <p>Yearly</p>
      </div>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n3" v-if="activePage.page == 4">
      <img />
      <p>
        Our forecast is based on market analysis, surveys of the population in
        different regions, as well as hoteliers and management staff of
        accommodations.
      </p>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n5" v-if="activePage.page == 5">
      <p>Fixed cost</p>
      <h1>{{ 0 }} €</h1>
      <p>fee: 10% + 1€</p>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 6">
      <img src="../assets/sport-main.png" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 7">
      <img src="../assets/sport-listing.png" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 8">
      <img src="../assets/sport-admin.png" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 9">
      <img src="../assets/sport-card.png" />
    </div>
  </Transition>
  <div class="navbar">
    <button class="back" v-if="activePage.page > 0" id="back" @click="prevPage">
      <img />Back
    </button>
    <button class="back" v-if="activePage.page < 1" id="back" @click="openPage('/')">
      <img />Home
    </button>
    <button class="next" :disabled="!validate() || activePage.page > 9" v-if="activePage.page < 8" id="next"
      @click="nextPage(validate())">
      Next<img />
    </button>
    <button v-if="activePage.page > (pages - 1)" id="restart" @click="activePage.page = 0">
      Restart
    </button>
    <button v-if="activePage.page == pages" id="home" @click="openPage('/')">
      Home
    </button>
  </div>
</template>

<style scoped>
button {
  background: var(--owner);
  color: #191919;
}

div.container-input.range {
  flex-direction: column;
  padding: 30px 20px 55px;
  gap: 20px;
}

div.container-input.range>h5 {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #515151;
}

.range>span.label {
  position: absolute;
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #797979;
  bottom: 25px;
  height: auto;
  top: unset;
}

.range>span.label:nth-child(3) {
  position: absolute;
  left: 30px;
  text-align: left;
}

.range>span.label:nth-child(4) {
  position: absolute;
  right: 30px;
  text-align: right;
}

input[type='range'] {
  -webkit-appearance: none;
  position: relative;
  padding: 0;
  background: #eeeeee;
  border-radius: 99px;
  cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  background-color: var(--owner);
  border-radius: 99px;
}

button.next img {
  content: url('../assets/btn-arrow-dark.svg')
}

button:hover {
  background: var(--owner-light);
}

@media screen and (max-width: 480px) {
  button:hover {
    background: var(--owner) !important;
  }

  div.container.n3>img {
    height: auto !important;
    width: 90% !important;
  }
}

.container.tip {
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
  gap: 15px;
  width: 100%;
  height: 87px;
  max-width: 500px;
  background: #fbfbfb;
  border-radius: 16px;
}

.container.tip>.container.item {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
  height: auto;
}

.container.item>img {
  width: 22px;
  height: 18px;
}

.container.item>img.fi {
  content: url("../assets/fi.png");
}

.container.item>img.eu {
  content: url("../assets/eu.png");
}

.container.item>p:nth-child(2) {
  font-size: 16px;
  line-height: 18px;
  width: 80%;
  text-align: left;
}

.container.item>p:nth-child(3) {
  font-size: 16px;
  line-height: 18px;
  width: auto;
  text-align: right;
}

div.container.n1 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 150px;
}

.load>h1 {
  width: 300px;
  max-width: 40vw;
  text-align: center;
}

div.finland-w {
  position: relative;
  box-sizing: border-box;
  height: 80%;
  width: auto;
  aspect-ratio: 125/219;
  background: url("../assets/finland.svg");
  background-size: cover;
}

.finland-w>.tooltip {
  position: absolute;
  cursor: pointer;
  opacity: 0.9;
}

.finland-w>.tooltip:hover {
  scale: 1.1;
  opacity: 1;
  transform: translateY(-10px);
}

.finland-w>.tooltip.n0 {
  left: 18.49%;
  right: 69.78%;
  top: 85.71%;
  bottom: 7.89%;
}

.finland-w>.tooltip.n1 {
  left: 37.4%;
  right: 50.87%;
  top: 88.96%;
  bottom: 4.64%;
}

.finland-w>.tooltip.n2 {
  left: 58.64%;
  right: 29.63%;
  top: 84.53%;
  bottom: 9.07%;
}

.finland-w>.tooltip.n3 {
  left: 37.4%;
  right: 50.87%;
  top: 79.81%;
  bottom: 13.8%;
}

.finland-w>.tooltip.n4 {
  left: 71.08%;
  right: 17.19%;
  top: 78.03%;
  bottom: 15.57%;
}

.finland-w>.tooltip.n5 {
  left: 27.29%;
  right: 60.97%;
  top: 66.22%;
  bottom: 27.39%;
}

.finland-w>.tooltip.n6 {
  left: 60.2%;
  right: 28.07%;
  top: 62.23%;
  bottom: 31.38%;
}

.finland-w>.tooltip.n7 {
  left: 81.96%;
  right: 6.31%;
  top: 65.33%;
  bottom: 28.28%;
}

.finland-w>.tooltip.n8 {
  left: 63.3%;
  right: 24.96%;
  top: 45.39%;
  bottom: 48.22%;
}

.finland-w>.tooltip.n9 {
  left: 52.42%;
  right: 35.84%;
  top: 18.36%;
  bottom: 75.25%;
}

div.container.n3 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 75px;
  padding-top: 5%;
}

div.container.n3>img {
  aspect-ratio: 193/110;
  height: 60%;
  content: url("../assets/sport-graph.png");
}

div.container.n3>p {
  max-width: 900px;
  font-size: 18px;
  line-height: 20px;
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.fade-enter-active::after,
.fade-leave-active::after {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>

<style>
.drop-enter-active,
.drop-leave-active {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.drop-enter-from,
.drop-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
