<template>
  <router-view></router-view>
</template>

<script>
/* eslint-disable */
import homePage from "./components/home.vue";
import hotelsPage from "./components/hotels.vue";
import sportPage from "./components/sport.vue";
import breakfastsPage from "./components/breakfasts.vue";
import ownerPage from "./components/owner.vue";
import trainerPage from "./components/trainer.vue";

const components = {
  homePage,
  hotelsPage,
  sportPage,
  breakfastsPage,
  ownerPage,
  trainerPage,
};

export default {
  name: "App",
  components: components,
  data: function () {
    return {};
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.$router.push(window.location.hash);
    });
  },
};
</script>

<style>
@import url(./assets/reset.css);

body {
  margin: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
}

div {
  box-sizing: border-box;
}

#app {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  background: #ffffff;
  height: 100vh;
  width: 100%;
}

button {
  cursor: pointer;
  outline: none;
  border: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  gap: 10px;
  height: 58px;
  background: #6080f5;
  border-radius: 8px;
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

button img {
  height: 12px;
  width: auto;
}

button.back img {
  content: url(./assets/arrow-dark.svg);
}

button.back:disabled img {
  content: url(./assets/arrow-disabled.svg);
}

button.next img {
  content: url(./assets/arrow-light.svg);
  rotate: 180deg;
}

button.next:disabled img {
  content: url(./assets/arrow-disabled.svg);
  rotate: 180deg;
}

button.back {
  color: #515151;
  background: #f8f8f8;
}

button.back:hover {
  background: #e3e3e3;
}

button:hover {
  background: #8196e4;
}

button:disabled {
  color: #d6d6d6;
  background: #f8f8f8;
  cursor: default;
}

button:disabled:active {
  background: #f8f8f8;
}

button:disabled:hover {
  background: #f8f8f8;
}

button:active {
  background: #3e59bb;
}

.container-input {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 30px;
  gap: 10px;
  max-width: 500px;
  width: 100%;
  background: #f8f8f8;
  box-sizing: border-box;
  border-radius: 32px;
}

.container-input span {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #d1caca;
  position: absolute;
  right: 36px;
  top: calc(50% - 26px / 2);
}

input {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #515151;
  border: none;
}

input:focus {
  outline: none;
}

input[name="nra"] p,
input[name="occ"] p {
  opacity: 0;
}

input[name="nra"]:focus p,
input[name="occ"]:focus p {
  opacity: 1;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url("./fonts/Graphik-Medium.woff2") format("woff2"),
    url("./fonts/Graphik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url("./fonts/Graphik-Regular.woff2") format("woff2"),
    url("./fonts/Graphik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url("./fonts/Graphik-Bold.woff2") format("woff2"),
    url("./fonts/Graphik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  transition: all 0.3s ease-in-out;
}
</style>

<style>
/*Containers global syle*/

div.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85%;
}

@media (max-width: 480px) {
  .navbar {
    position: fixed !important;
  }

  .service-cont img {
    max-width: 300px !important;
  }

  div.container,
  .navbar {
    padding: 18px !important;
  }

  input {
    border-radius: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  div.container.n0,
  div.container.n2 {
    gap: 15px !important;
  }

  div.container.n2,
  div.container.n6 {
    flex-direction: column !important;
  }

  h1 {
    font-size: 60px !important;
    line-height: 66px !important;
  }

  .ball.small {
    max-width: 200px !important;
  }

  .ball.big {
    max-width: 300px !important;
  }

  .ball.small h2 {
    font-size: 38.9px !important;
    line-height: 43px !important;
  }

  .ball.big h2 {
    font-size: 70.9615px !important;
    line-height: 78px !important;
  }

  .ball p {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  div.container.pic0 img,
  div.container.pic1 img,
  div.container.pic2 img {
    height: auto !important;
    width: 100%;
  }

  div.container.pic1 img {
    content: url(./assets/phone-mobile.png) !important;
    height: 100% !important;
    width: auto !important;
    aspect-ratio: unset !important;
  }

  button:hover {
    background: #6080f5 !important;
  }

  button:disabled:hover {
    background: #f8f8f8 !important;
  }
}

div.container.n0 {
  padding: 0px;
  gap: 20px;
}

div.container.n1 {
  padding: 0px;
  gap: 25px;
}

div.container.n2,
div.container.n6 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 50px;
}

div.container.n3,
div.container.n4,
div.container.n5,
div.container.n7 {
  padding: 0px;
  gap: 50px;
}

div.container.n6 {
  padding: 0px;
  gap: 100px;
}

.dd-hours img {
  width: 12px;
  height: 12px;
  content: url(./assets/chevron-down.svg);
}

div.container.pic0,
div.container.pic1,
div.container.pic2 {
  box-sizing: border-box;
  padding: 0px;
  gap: 50px;
}

div.container.pic0 img {
  height: 100%;
  content: url(./assets/tablet-admin.png);
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

div.container.pic1 img {
  height: 100%;
  content: url(./assets/phone.png);
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

div.container.pic2 img {
  height: 100%;
  content: url(./assets/tablet-listing.png);
  object-fit: cover;
  aspect-ratio: 1 / 1;
}
</style>

<style>
/* Switch */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #6080f5;
}

input:focus+.slider {
  box-shadow: 0 0 1px #6080f5;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.service-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 25px;
  width: 100%;
  max-width: 403px;
  height: auto;
}

.service-cont img {
  max-width: 403px;
}

.service-cont.booking img {
  content: url(./assets/booking-logo.png);
}

.service-cont.expedia img {
  content: url(./assets/expedia-logo.png);
}
</style>
