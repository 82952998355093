<script>
/* eslint-disable */
import { reactive } from "vue";

export var perMonth = 15000;
export var hourSummary = 14400;
export var hourUnused = 14400;

export var activePage = reactive({
  page: 0, // activePage
  test: 1,
});

const regions = {
  0: {
    load: 10,
  },
  1: {
    load: 11,
  },
  2: {
    load: 12,
  },
  3: {
    load: 13,
  },
  4: {
    load: 14,
  },
  5: {
    load: 15,
  },
  6: {
    load: 16,
  },
  7: {
    load: 17,
  },
  8: {
    load: 18,
  },
  9: {
    load: 19,
  },
};

export default {
  name: "trainerPage",
  props: {
    msg: String,
  },
  data: function () {
    return {
      activePage,
      breakfNum: "",
      breakfRate: "",
      load: regions[0].load,
      isShow: false,
      activeHours: 8,
      minHours: 2,
      yearly: false,
      pages: 4, // quantity of pages
      regions,
    };
  },
  methods: {
    nextPage(bool) {
      if (bool) {
        activePage.page++;
      }
    },
    prevPage() {
      activePage.page--;
    },
    validate() {
      return true
    },
    monthlyYearly() {
      if (!this.yearly) {
        return 1;
      } else {
        return 12;
      }
    },
    calculateMonthly() {
      return (
        (this.breakfNum * this.breakfRate) * this.monthlyYearly()
      ).toFixed(0);
    },
    openPage(page) {
      this.$router.push(page);
      activePage.page = 0;
    },
  },
};
</script>

<template>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 0">
      <img src="../assets/trainer-listing.png" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 1">
      <img src="../assets/trainer-card.png" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 2">
      <img src="../assets/trainer-calendar.png" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 3">
      <img src="../assets/trainer-site.png" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n5" v-if="activePage.page == 4">
      <p>Fixed cost</p>
      <h1>{{ 0 }} €</h1>
      <p>fee: 10% + 1€</p>
    </div>
  </Transition>
  <div class="navbar">
    <button class="back" v-if="activePage.page > 0" id="back" @click="prevPage">
      <img />Back
    </button>
    <button class="back" v-if="activePage.page < 1" id="back" @click="openPage('/')">
      <img />Home
    </button>
    <button class="next" v-if="activePage.page < pages" id="next" @click="nextPage(validate())">
      Next<img />
    </button>
    <button v-if="activePage.page > (pages - 1)" id="restart" @click="activePage.page = 0">
      Restart
    </button>
    <button v-if="activePage.page == pages" id="home" @click="openPage('/')">
      Home
    </button>
  </div>
</template>

<style scoped>
button {
  background: var(--trainer);
  color: #191919;
}

button.next img {
  content: url('../assets/btn-arrow-dark.svg')
}

button:hover {
  background: var(--trainer-light);
}

@media screen and (max-width: 480px) {
  button:hover {
    background: var(--trainer) !important;
  }
}

.container.tip {
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
  gap: 15px;
  width: 100%;
  height: 87px;
  max-width: 500px;
  background: #fbfbfb;
  border-radius: 16px;
}

.container.tip>.container.item {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
  height: auto;
}

.container.item>img {
  width: 22px;
  height: 18px;
}

.container.item>img.fi {
  content: url("../assets/fi.png");
}

.container.item>img.eu {
  content: url("../assets/eu.png");
}

.container.item>p:nth-child(2) {
  font-size: 16px;
  line-height: 18px;
  width: 80%;
  text-align: left;
}

.container.item>p:nth-child(3) {
  font-size: 16px;
  line-height: 18px;
  width: auto;
  text-align: right;
}

div.container.n1 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 150px;
}

.load>h1 {
  width: 300px;
  max-width: 40vw;
  text-align: center;
}

div.finland-w {
  position: relative;
  box-sizing: border-box;
  height: 80%;
  width: auto;
  aspect-ratio: 125/219;
  background: url("../assets/finland.svg");
  background-size: cover;
}

.finland-w>.tooltip {
  position: absolute;
  cursor: pointer;
  opacity: 0.9;
}

.finland-w>.tooltip:hover {
  scale: 1.1;
  opacity: 1;
  transform: translateY(-10px);
}

.finland-w>.tooltip.n0 {
  left: 18.49%;
  right: 69.78%;
  top: 85.71%;
  bottom: 7.89%;
}

.finland-w>.tooltip.n1 {
  left: 37.4%;
  right: 50.87%;
  top: 88.96%;
  bottom: 4.64%;
}

.finland-w>.tooltip.n2 {
  left: 58.64%;
  right: 29.63%;
  top: 84.53%;
  bottom: 9.07%;
}

.finland-w>.tooltip.n3 {
  left: 37.4%;
  right: 50.87%;
  top: 79.81%;
  bottom: 13.8%;
}

.finland-w>.tooltip.n4 {
  left: 71.08%;
  right: 17.19%;
  top: 78.03%;
  bottom: 15.57%;
}

.finland-w>.tooltip.n5 {
  left: 27.29%;
  right: 60.97%;
  top: 66.22%;
  bottom: 27.39%;
}

.finland-w>.tooltip.n6 {
  left: 60.2%;
  right: 28.07%;
  top: 62.23%;
  bottom: 31.38%;
}

.finland-w>.tooltip.n7 {
  left: 81.96%;
  right: 6.31%;
  top: 65.33%;
  bottom: 28.28%;
}

.finland-w>.tooltip.n8 {
  left: 63.3%;
  right: 24.96%;
  top: 45.39%;
  bottom: 48.22%;
}

.finland-w>.tooltip.n9 {
  left: 52.42%;
  right: 35.84%;
  top: 18.36%;
  bottom: 75.25%;
}

div.container.n3 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 75px;
  padding-top: 5%;
}

div.container.n3>img {
  aspect-ratio: 193/110;
  height: 60%;
  content: url("../assets/graph.png");
}

div.container.n3>p {
  max-width: 900px;
  font-size: 18px;
  line-height: 20px;
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.fade-enter-active::after,
.fade-leave-active::after {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>

<style>
.drop-enter-active,
.drop-leave-active {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.drop-enter-from,
.drop-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
