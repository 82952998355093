<script>
/* eslint-disable */
import { reactive } from "vue";

export var perMonth = 15000;
export var hourSummary = 14400;
export var hourUnused = 14400;

export var activePage = reactive({
    page: 0,
    test: 1,
});
export default {
    name: "homePage",
    props: {
        msg: String,
    },
    data: function () {
        return {
            activePage,
            numberOfRooms: "",
            nightRate: "",
            occupancy: "",
            perMonth,
            hourSummary,
            hourUnused,
            isShow: false,
            activeHours: 8,
            minHours: 2,
            yearly: false,
        };
    },
    methods: {
        openPage(page) {
            this.$router.push(page);
        },
    },
};
</script>

<template>
    <Transition name="fade" mode="in-out">
        <div class="container fullscreen calcs-p">
            <div class="c-w" ref="cardsw">
                <div class="card-calc-box hotels" @click="openPage('/hotels')">
                    <div class="card hotels">
                        <h2>Hourly Bookings Presentation</h2>
                        <img />
                    </div>
                </div>
                <div class="card-calc-box breakf" @click="openPage('/breakfasts')">
                    <div class="card breakf">
                        <h2>Selling Breakfast Benefits</h2>
                        <img />
                    </div>
                </div>
                <div class="card-calc-box sport" @click="openPage('/sport')">
                    <div class="card sport">
                        <h2>Getsport service presentation</h2>
                        <img />
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
h1,
h2 {
    text-align: left;
}

.container.fullscreen.calcs-p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px;
    gap: 100px;
}

.container.fullscreen.calcs-p>h2.head {
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 100%;
    text-align: center;
    color: #8c8db2;
    width: 100%;
    max-width: 500px;
}

.container.fullscreen.calcs-p>div.c-w {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 30px;
    max-width: 1020px;
    height: 100%;
    width: 100%;
}

/* Calc cards */
div.card-calc-box {
    box-sizing: border-box;
    width: 320px;
    height: 340px;
    background: #fff;
    border-radius: 14px;
    position: relative;
}

div.card-calc-box>div.card {
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #ffffff;
    border-radius: 14px;
}

div.card-calc-box:hover>div.card {
    transform: translate(10px, 10px);
}

div.card-calc-box>div.card>h2 {
    position: absolute;
    left: 18px;
    right: 18px;
    top: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 100%;
    color: #040404;
}

div.card-calc-box>div.card>img {
    position: absolute;
    width: 110px;
    height: 90px;
    left: 18px;
    bottom: 48px;
}

div.card-calc-box>div.card>a {
    position: absolute;
    right: 18px;
    bottom: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
}

div.card-calc-box.hotels:hover {
    background: #6080f5;
}

div.card-calc-box.breakf:hover {
    background: #e77e51;
}

div.card-calc-box.sport:hover {
    background: #a0cb24;
}

div.card-calc-box>div.card.hotels {
    border: 1px solid #6080f5;
}

div.card-calc-box>div.card.hotels>img {
    content: url(../assets/arrow-big-blue.svg);
}

div.card-calc-box>div.card.breakf {
    border: 1px solid #e77e51;
}

div.card-calc-box>div.card.breakf>img {
    content: url(../assets/arrow-big-orange.svg);
}

div.card-calc-box>div.card.sport {
    border: 1px solid #a0cb24;
}

div.card-calc-box>div.card.sport>img {
    content: url(../assets/arrow-big-green.svg);
}

div.card-calc-box>div.card.hotels>h2,
div.card-calc-box>div.card.hotels>a {
    color: #6080f5;
}

div.card-calc-box>div.card.breakf>h2,
div.card-calc-box>div.card.breakf>a {
    color: #e77e51;
}

div.card-calc-box>div.card.sport>h2,
div.card-calc-box>div.card.sport>a {
    color: #a0cb24;
}

@media (max-width: 480px) {
    .c-w {
        flex-direction: column !important;
        gap: 10px !important;
    }

    div.card-calc-box {
        height: 175px;
    }

    div.card-calc-box>div.card>img {
        left: unset;
        right: 18px;
        top: 18px;
        width: 24px;
        height: auto;
    }
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease-in-out;
    position: absolute;
}

.fade-enter-active::after,
.fade-leave-active::after {
    transition: all 0.3s ease-in-out;
    position: absolute;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scale(0.95);
}
</style>

<style>
.drop-enter-active,
.drop-leave-active {
    transition: all 0.3s ease-in-out;
    position: absolute;
}

.drop-enter-from,
.drop-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}
</style>
