<script>
/* eslint-disable */
import { reactive } from "vue";

export var perMonth = 15000;
export var hourSummary = 14400;
export var hourUnused = 14400;

export var activePage = reactive({
  page: 0,
  test: 1,
});
export default {
  name: "hotelsPage",
  props: {
    msg: String,
  },
  data: function () {
    return {
      activePage,
      numberOfRooms: "",
      nightRate: "",
      occupancy: "",
      perMonth,
      hourSummary,
      hourUnused,
      isShow: false,
      activeHours: 8,
      minHours: 2,
      yearly: false,
      pages: 12
    };
  },
  methods: {
    nextPage(bool) {
      if (bool) {
        activePage.page++;
      }
    },
    prevPage() {
      activePage.page--;
    },
    validate() {
      if (this.numberOfRooms > 0 && this.nightRate > 0 && this.occupancy > 0) {
        return true;
      } else {
        return false;
      }
    },
    monthlyYearly() {
      if (!this.yearly) {
        return 30;
      } else {
        return 30 * 12;
      }
    },
    calculateMonthly() {
      return (
        ((this.numberOfRooms * this.nightRate * 30) / 100) *
        this.occupancy
      ).toFixed(0);
    },
    calculateUnused() {
      return (
        ((this.numberOfRooms * 24 * 30) / 100) *
        (100 - this.occupancy)
      ).toFixed(0);
    },
    calculateHours() {
      return (((this.numberOfRooms * 24 * 30) / 100) * this.occupancy).toFixed(
        0
      );
    },
    calculateHourlyPrice() {
      return (this.nightRate / this.activeHours).toFixed(2);
    },
    calculateLossed() {
      return (
        this.calculateHourlyPrice() *
        this.minHours *
        (this.numberOfRooms / 5) *
        this.monthlyYearly()
      ).toFixed(0);
    },
    openPage(page) {
      this.$router.push(page);
      activePage.page = 0;
    },
  },
};
</script>

<template>
  <Transition name="fade" mode="in-out">
    <div class="container n0" v-if="activePage.page == 0">
      <div class="container-input">
        <input type="text" inputmode="numeric" placeholder="Number of rooms" v-model.number="numberOfRooms" />
      </div>
      <div class="container-input">
        <input type="text" inputmode="numeric" placeholder="Avg night rate" v-model.number="nightRate" />
        <span>€</span>
      </div>
      <div class="container-input">
        <input type="text" inputmode="numeric" placeholder="Avg occupancy" :maxlength="3" v-model.number="occupancy" />
        <span>%</span>
      </div>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n1" v-if="activePage.page == 1">
      <h1>{{ calculateMonthly() }} €</h1>
      <p>Per Month</p>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n2" v-if="activePage.page == 2">
      <div class="ball small">
        <h2>{{ calculateHours() }}</h2>
        <p>Summary of hours</p>
      </div>
      <div class="ball big">
        <h2>{{ calculateUnused() }}</h2>
        <p>Unused hours</p>
      </div>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n3" v-if="activePage.page == 3">
      <div class="hours-container">
        <p>{{ nightRate }} € /</p>
        <div class="dd-wrapper">
          <div class="dd-hours" @click="isShow = true">
            {{ activeHours }} hours
            <img src="" alt="" />
          </div>
          <Transition name="drop">
            <div v-if="isShow" class="dropdown">
              <div class="item" @click="activeHours = 8; isShow = false;">
                8 hours
              </div>
              <div class="item" @click="activeHours = 10;
isShow = false;
              ">
                10 hours
              </div>
              <div class="item" @click="
  activeHours = 12;
isShow = false;
              ">
                12 hours
              </div>
              <div class="item" @click="
  activeHours = 14;
isShow = false;
              ">
                14 hours
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <h1>≈{{ calculateHourlyPrice() }} €</h1>
      <p>Hourly price</p>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n4" v-if="activePage.page == 4">
      <div class="hours-container maxunset">
        <p>With</p>
        <div class="dd-wrapper">
          <div class="dd-hours" @click="isShow = true">
            {{ minHours }} hours
            <img src="" alt="" />
          </div>
          <Transition name="drop">
            <div v-if="isShow" class="dropdown">
              <div class="item" @click="
  minHours = 2;
isShow = false;
              ">
                2 hours
              </div>
              <div class="item" @click="
  minHours = 4;
isShow = false;
              ">
                4 hours
              </div>
              <div class="item" @click="
  minHours = 6;
isShow = false;
              ">
                6 hours
              </div>
            </div>
          </Transition>
        </div>
        <p>and 25% occupancy it comes to...</p>
      </div>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n5" v-if="activePage.page == 5">
      <p>Lossed income is</p>
      <h1>{{ calculateLossed() }} €</h1>
      <div class="hours-container">
        <p>Monthly</p>
        <label class="switch">
          <input type="checkbox" v-model="yearly" />
          <span class="slider round"></span>
        </label>
        <p>Yearly</p>
      </div>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n6" v-if="activePage.page == 6">
      <div class="service-cont booking">
        <img src="" alt="" />
        <p>from 15%</p>
      </div>
      <div class="service-cont expedia">
        <img src="" alt="" />
        <p>from 15%</p>
      </div>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container n7" v-if="activePage.page == 7">
      <p>With <img src="../assets/stayuse-logo.svg" alt=""> You pay</p>
      <h1>0€</h1>
      <p>and 10% fee</p>
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 8">
      <img src="../assets/hotels-main.png" alt="" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 9">
      <img src="../assets/hotels-listing.png" alt="" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 10">
      <img src="../assets/hotels-calendar.png" alt="" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 11">
      <img src="../assets/hotels-invoicing.png" alt="" />
    </div>
  </Transition>
  <Transition name="fade" mode="in-out">
    <div class="container img1610" v-if="activePage.page == 12">
      <img src="../assets/hotels-mobile.png" alt="" />
    </div>
  </Transition>
  <div class="navbar">
    <button class="back" v-if="activePage.page > 0" id="back" @click="prevPage">
      <img />Back
    </button>
    <button class="back" v-if="activePage.page < 1" id="back" @click="openPage('/')">
      <img />Home
    </button>
    <button class="next" :disabled="!validate() || activePage.page > (pages - 1)" v-if="activePage.page < pages"
      id="next" @click="nextPage(validate())">
      Next<img />
    </button>
    <button v-if="activePage.page > (pages - 1)" id="restart" @click="activePage.page = 0">
      Restart
    </button>
    <button v-if="activePage.page == pages" id="home" @click="openPage('/')">
      Home
    </button>
  </div>
</template>

<style scoped>
p {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

@media (max-width: 480px) {
  div.hours-container {
    flex-wrap: wrap;
  }
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.fade-enter-active::after,
.fade-leave-active::after {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>

<style>
.drop-enter-active,
.drop-leave-active {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.drop-enter-from,
.drop-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.maxunset {
  max-width: unset !important;
}
</style>
