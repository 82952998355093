/* eslint-disable */

import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";

import "./assets/main.css";
import homePage from "./components/home.vue";
import hotelsPage from "./components/hotels.vue";
import sportPage from "./components/sport.vue";
import breakfastsPage from "./components/breakfasts.vue";
import ownerPage from "./components/owner.vue";
import trainerPage from "./components/trainer.vue";

const app = createApp(App);

const routes = [
    { path: "/", component: homePage },
    { path: "/breakfasts", component: breakfastsPage },
    { path: "/hotels", component: hotelsPage },
    { path: "/sport", component: sportPage },
    { path: "/owner", component: ownerPage },
    { path: "/trainer", component: trainerPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

app.use(router);
app.mount("#app");
