<script>
/* eslint-disable */
import { reactive } from "vue";

export var perMonth = 15000;
export var hourSummary = 14400;
export var hourUnused = 14400;

export var activePage = reactive({
  page: 0,
  test: 1,
});
export default {
  name: "sportPage",
  props: {
    msg: String,
  },
  data: function () {
    return {
      activePage,
      numberOfRooms: "",
      nightRate: "",
      occupancy: "",
      perMonth,
      hourSummary,
      hourUnused,
      isShow: false,
      activeHours: 8,
      minHours: 2,
      yearly: false,
    };
  },
  methods: {
    openPage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<template>
  <Transition name="fade" mode="in-out">
    <div class="container fullscreen calcs-p" id="kurwa">
      <div class="c-w" ref="cardsw">
        <div class="card-calc-box coach" @click="openPage('/trainer')">
          <div class="card" id="murwa">
            <h2>Coach</h2>
            <p class="fillw">
              Advantages of the service for trainers, massage therapists, and psychologists
            </p>
            <img />
          </div>
        </div>
        <div class="card-calc-box owner" @click="openPage('/owner')">
          <div class="card">
            <h2>Owner</h2>
            <p class="fillw">Advantages for owners of sports grounds, halls and arenas</p>
            <img />
          </div>
        </div>
        <div class="card-calc-box home" @click="openPage('/')">
          <div class="card">
            <p class="fillw">back to home</p>
          </div>
        </div>
        <div class="bg-wrapper"></div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
h1,
h2 {
  text-align: left;
}

.container.fullscreen.calcs-p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  gap: 100px;
}

.container.fullscreen.calcs-p>h2.head {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 100%;
  text-align: center;
  color: #8c8db2;
  width: 100%;
  max-width: 500px;
}

.container.fullscreen.calcs-p>div.c-w {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 15px;
  max-width: 1020px;
  height: 100%;
  width: 100%;
}

/* Calc cards */
div.card-calc-box {
  z-index: 10;
  box-sizing: border-box;
  width: 320px;
  border-radius: 14px;
  position: relative;
}

div.card-calc-box.home p {
  text-align: center !important;
}

div.card-calc-box.home:hover p {
  color: #fff;
}

div.card-calc-box.home:hover {
  background-color: #191919;
}

div.card-calc-box>div.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  isolation: isolate;
  border: 1px solid #191919;
  border-radius: 14px;
  cursor: pointer;
}

div.bg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: transparent;
}

div.card-calc-box.coach:hover~div.bg-wrapper {
  background-color: #a0cb24 !important;
}

div.card-calc-box.owner:hover~div.bg-wrapper {
  background-color: #34CC27 !important;
}

div.card-calc-box>div.card>h2 {
  user-select: none;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 100%;
  color: #191919;
}

div.card-calc-box>div.card>p {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #191919;
}

div.card-calc-box>div.card>img {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 18px;
  top: 18px;
  content: url('../assets/sport-arrow.svg');
}

div.card-calc-box>div.card>a {
  position: absolute;
  right: 18px;
  bottom: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
}


@media (max-width: 480px) {
  .c-w {
    gap: 10px !important;
  }

  div.card-calc-box {
    height: auto
  }

  div.card-calc-box>div.card>img {
    left: unset;
    right: 18px;
    top: 18px;
    width: 24px;
    height: auto;
  }
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.fade-enter-active::after,
.fade-leave-active::after {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>

<style>
.drop-enter-active,
.drop-leave-active {
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.drop-enter-from,
.drop-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
